<template>
  <div class="start" :class="$mq">
      <AltHeroBanner
        :title="$t('start.title')"
        :image="require('../assets/img/bg-header.jpg')"
      />
      <StartProjectForm class="my-5" />
  </div>
</template>

<script>
import StartProjectForm from '@/components/Form/StartProjectForm.vue';
import AltHeroBanner from '@/components/Banner/AltHeroBanner.vue'

export default {
  name: 'Start',
  components: {
    StartProjectForm,
    AltHeroBanner
  }
}
</script>

<style lang="scss" scoped>
  .start {
    
  }
</style>