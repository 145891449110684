<template>
    <div class="container">
        <form class="project-form">
            <h3>{{ $t('start.vous') }}</h3>
            <div class="form-row row">
                <div class="form-group col-md-6">
                    <label for="inputFirstName">{{ $t('form.name.first') }}</label>
                    <input v-model="$v.form.name.first.$model" type="text" class="form-control" id="inputFirstName">
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.name.first.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputLastName">{{ $t('form.name.last') }}</label>
                    <input v-model="$v.form.name.last.$model" type="text" class="form-control" id="inputLastName">
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.name.last.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
            </div>
            <div class="form-row row">
                <div class="form-group col-md-6">
                    <label for="inputEmail">{{ $t('form.email') }}</label>
                    <input v-model.trim.lazy="$v.form.email.$model" type="email" class="form-control" id="inputEmail">
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.email.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.email.email">
                        {{ $t('form.invalid-email') }}
                    </p>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPhone">{{ $t('form.phone') }}</label>
                    <cleave
                        v-model="$v.form.phoneNumber.$model"
                        :options="{
                            phone: true,
                            phoneRegionCode: 'FR'
                        }"
                        class="form-control"
                        id="inputPhone"
                    ></cleave>
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.phoneNumber.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
            </div>

            <h3 class="mt-5">{{ $t('start.projet') }}</h3>
            <div class="form-row project-selector" :class="$mq">
                <div class="form-group project-type" @click="selectType('vitrine')" :class="{isActive : activeType.indexOf('vitrine') !== -1 }">
                    <span class="icon mdi mdi-responsive"></span>
                    <p class="mb-0">Site vitrine responsive</p>
                </div>
                <div class="form-group project-type" @click="selectType('ecomm')" :class="{isActive : activeType.indexOf('ecomm') !== -1 }">
                    <span class="icon mdi mdi-basket-fill"></span>
                    <p class="mb-0">Site e-commerce responsive</p>
                </div>
                <div class="form-group project-type" @click="selectType('solution')" :class="{isActive : activeType.indexOf('solution') !== -1 }">
                    <span class="icon mdi mdi-desktop-mac-dashboard"></span>
                    <p class="mb-0">Solution web sur-mesure</p>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="formBudgetRange">{{ $t('start.budget') }}</label>
                    <vue-range-slider
                        v-model="$v.form.projectBudget.$model"
                        :min="300"
                        :max="20000"
                        :interval="100"
                        tooltip="always"
                        :tooltip-formatter="value => value.toLocaleString(locale)+'€'"
                        :process="dotsPos => [
                            [dotsPos[0], dotsPos[1], { backgroundColor: '#009C9A' }]
                        ]"
                        class="form-control-range"
                        id="formBudgetRange"
                    ></vue-range-slider>
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.projectBudget.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="projectDate">{{ $t('start.date') }}</label>
                    <datepicker
                        v-model="$v.form.date.$model"
                        :language="languages[locale]"
                        :monday-first="true"
                        :bootstrap-styling="true"
                        calendar-class="date-picker-calendar"
                        input-class="form-control"
                        format="d MMMM yyyy"
                        id="projectDate"
                    ></datepicker>
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.date.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="projectDescription">{{ $t('start.desc') }}</label>
                    <textarea v-model="$v.form.description.$model" class="form-control" id="projectDescription" rows="3"></textarea>
                    <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.description.required && submitStatus === 'ERROR'">
                        {{ $t('form.required') }}
                    </p>
                </div>
            </div>

            <button class="btn btn-primary mt-3" @click.prevent="send" :disabled="submitStatus === 'PENDING'">{{ $t('start.envoyer') }}</button>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'

import Cleave from 'vue-cleave-component';
require('cleave.js/dist/addons/cleave-phone.fr');

import VueRangeSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css'

import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";

export default {
    name: "StartProjectForm",
    data () {
        return {
            form: {
                name: {
                    first: null,
                    last: null
                },
                email: null,
                phoneNumber: null,
                projectType: [],
                projectBudget: [6000, 14000],
                date: null,
                description: null
            },
            activeType: [],
            languages: lang,
            submitStatus: null,
            snackbarOptions: {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            }
        }
    },
    validations: {
        form: {
            name: {
                first: { required },
                last: { required }
            },
            email: { required, email },
            phoneNumber: { required },
            projectType: { },
            projectBudget: { required },
            date: { required },
            description: { required }
        },
    },
    components: {
        Cleave,
        VueRangeSlider,
        Datepicker
    },
    computed: {
        ...mapGetters({
            locale: 'ui/locale',
        }),
    },
    methods: {
        selectType(type) {
            let index = this.activeType.indexOf(type);
            console.log(index)

            if (index !== -1) {
                this.form.projectType.splice(index, 1);
                this.activeType.splice(index, 1);
            } else {
                this.form.projectType.push(type);
                this.activeType.push(type);
            }
        },
        send() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'

                window.emailjs.send('smtp_olina_lab', 'template_c11ib2n', this.form)
                    .then(() => {
                        this.$toast.success("Merci, votre message a bien été transmis !", this.snackbarOptions);
                        this.form = {
                            name: {
                                first: null,
                                last: null
                            },
                            email: null,
                            phoneNumber: null,
                            projectType: [],
                            projectBudget: [6000, 14000],
                            date: null,
                            description: null
                        }          
                        this.submitStatus = 'OK' 
                    })
                    .catch((e) => {
                        this.$toast.error("Oops, une erreur est survenue...", this.snackbarOptions);
                        this.form = {
                            name: {
                                first: null,
                                last: null
                            },
                            email: null,
                            phoneNumber: null,
                            projectType: [],
                            projectBudget: [6000, 14000],
                            date: null,
                            description: null
                        }
                        this.submitStatus = 'ERROR'
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.project-form {
    .form-control {
        font-size: 18px;
        box-shadow: none;
        display: block;
        width: 100%;
        min-height: 60px;
        padding: 18px 25px;
        font-weight: 300;
        line-height: 24px;
        color: #29293a;
        background-color: #f2f3f9;
        background-image: none;
        border: 0px solid #f2f3f9;
        border-radius: 35px;
        -webkit-appearance: none;
    }

    label {
        padding-left: 21px;
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: .02em;
        color: #838386;
        pointer-events: auto;
        cursor: pointer;
    }

    button, button:focus {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        min-width: 230px;
        padding: 14px 38px;
        border-radius: 35px;
        transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
        box-shadow: none;

        &:hover {
	        background-color: $secondary;
            border-color: $secondary;
        }
    }

    button:disabled, button[disabled] {
        &:hover {
            background-color: $primary !important;
            border-color: $primary !important;
        }
    }

    .project-selector {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        margin: 50px 0;

        .project-type {
            cursor: pointer;
            padding: 15px;
            text-align: center;
            color: #838386;
            width: 250px;

            .icon {
                font-size: 5rem;
            }

            &.isActive {
                padding: 10px;
                background-color: rgba(255, 255, 255, .5);
                -webkit-transition: background-color 0.3s linear;
                -ms-transition: background-color 0.3s linear;
                transition: background-color 0.3s linear;
                border: solid 5px $white;
                border-radius: 35px;
                color: $white;
            }
        }

        &.tablet {
            .project-type {
                width: 200px;

                i {
                    font-size: 3rem;
                }
            }
        }

        &.mobile {
            grid-template-columns: 235px;
            justify-content: center; 

            .project-type:not(:last-child) {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
}
</style>